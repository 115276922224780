import axios from 'axios';
import serialize from 'form-serialize';
import type { AxiosPromise } from 'axios';

// Analytics
function trackMomentsDownload() {
  let label = '';
  if (window.location.pathname === '/gg') {
    label = 'GG Hero';
  } else if (window.location.pathname === '/gg/moments') {
    label = 'Moments Hero';
  } else {
    label = window.location.pathname;
  }

  if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push({
      event: 'SendEvent',
      eventCategory: 'GG Download',
      eventAction: 'Download',
      eventLabel: label,
    });
  }
}

// Helpers
function requestEmail(location: string, data) {
  return axios.post(location, data, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });
}

function initDownload(el) {
  const link = el.querySelector('a');

  if (link instanceof HTMLAnchorElement) {
    const { redirect } = link.dataset;

    if (redirect) {
      link.addEventListener('click', () => {
        trackMomentsDownload();
        setTimeout(function () {
          window.location = redirect;
        }, 2000);
      });
    }
  }

  // Send Email Download button
  const sendEmailDownloadForm = el.querySelector('.link-download__form');
  if (sendEmailDownloadForm instanceof HTMLFormElement) {
    sendEmailDownloadForm.addEventListener('submit', (event) => {
      event.preventDefault();

      // Validate form
      const email = el.querySelector('.link-download__download-email');
      if (email instanceof HTMLInputElement) {
        if (email.value === '') {
          const parentDiv = el.querySelector('.link-download__email-link');
          if (parentDiv instanceof HTMLDivElement) {
            parentDiv.classList.add('link-download__validation_failed');
          }
          return false;
        }


        const data = serialize(sendEmailDownloadForm);
        const action = sendEmailDownloadForm.getAttribute('action');

        requestEmail(action, data).then(
          (response) => {
            const successMessage = el.querySelector('.link-download__success-message');
            if (successMessage instanceof HTMLDivElement) {
              successMessage.classList.remove('link-download__hidden');
            }
            const emailDownloadFormDiv = el.querySelector('.link-download__email-link');
            const emailDescription = el.querySelector('.link-download__email-description');

            if (emailDownloadFormDiv instanceof HTMLDivElement &&
              emailDescription instanceof HTMLDivElement) {
              emailDownloadFormDiv.classList.add('link-download__hidden');
              emailDescription.classList.add('link-download__hidden');
            }
          },
          (error) => {
            const errorMessages = [];
            if (error.response) {
              // We got a response from the server, just output all of them
              Object.keys(error.response.data).forEach(key => {
                const messages = error.response.data[key];
                messages.forEach(m => errorMessages.push(m));
              });
            } else {
              // Something else went wrong, use a generic message.
              errorMessages.push('Something went wrong, please try again later.');
            }
            console.log(errorMessages);
          },
        );
      }
    });
  }
}

const downloads = Array.from(document.querySelectorAll('.link-download'));
downloads.forEach(initDownload);
